import { Subscription } from 'rxjs';
import { Months, Active_Status, Mcb_Status, Valve_Status, Communication_Status, BillStatus, WalletBalanceStatus, Activation_Status } from './../../shared/constants/enum';
import { Router } from '@angular/router';
import { Component } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { LoaderService } from '../../core/services/loader.service';
import { deleteFaqs, getFaqs } from '../../store/Faq/faq.actions';
import {
  delateFaqSuccess,
  deleteFaqFailure,
  getFaqFailure,
  getFaqSuccess,
} from '../../store/Faq/faq-selector';
import { SharedService } from '../../shared/shared.service';
import { ConfirmationModal, Faq } from '../../shared/modals/common';
import {
  batteryLevel,
  Roles,
  SweetAlertIcon,
} from '../../shared/constants/enum';
import { FaqService } from '../../core/services/faq.service';
import { PageChangeEvent } from '@progress/kendo-angular-grid';
import { table_pageSize } from '../../shared/constants/templatesNames';
import { formatDate } from '@angular/common';  // <-- Import formatDate from common
import { BsDatepickerConfig, BsDaterangepickerDirective } from 'ngx-bootstrap/datepicker';
import { log } from 'util';
import { ReportsService } from '../../core/services/reports.service';
import { FormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Validations } from '../../shared/constants/validations';
import { AnyARecord } from 'dns';
@Component({
  selector: 'app-rp-reports',
  templateUrl: './rp-reports.component.html',
  styleUrl: './rp-reports.component.scss',
})
export class RpReportsComponent {
  isFirstOpen: boolean = true;
  isopen: boolean = false

  breadCrumbItems: Array<{}> | undefined;
  selectedProject: any = [];
  selectedSettlementProject: any = [];
  selectedUtility: any = ['all'];
  consumption_selectedUtility: any = ['all'];
  dailyconsumption_selectedUtility: any = ['all']
  billwise_selectedUtility: any = ['all'];
  deviceCommunication_selectedUtility: any = ['all'];
  meterReading_selectedUtility: any = ['all'];
  billgeneration_selectedUtility: any = ['all'];
  walletHistory_selectedUtility: any = ['all'];
  selectedBatteryLevel: string = 'all';
  configData: any = [];
  public isChecked = false;
  isUtilityChecked = false;
  isBatteryChecked = false;
  batteryLevel = batteryLevel;
  batterLevelDup = batteryLevel;
  projectsList: any = [];
  projectsDupList: any = [];
  utilityList: any = [];
  utilitylistDup: any = [];
  listOfRoles = Roles;
  role: any;
  activationDateRange: any = [];
  settlemetDateRange: any[] = []
  selectedYearMonth: any;
  consumptionDateRange: any = [];
  dailyconsumptionDateRange: any = [];
  deviceCommunicationDateRange: any = [];
  meterReadingDateRange: any = [];
  billGenerationDateRange: any = [];
  walletBalanceDateRange: any = [];
  walletHistoryDateRange: any = [];
  billwiseDateRange: any = [];
  rangeList: any = [{ id: 'Day Wise', name: 'Day Wise' }, { id: 'Range Wise', name: 'Range Wise' }];
  datePickerConfig: Partial<BsDatepickerConfig>;
  meterReadingsReportDates: Partial<BsDatepickerConfig>;
  // datePickerConfigwithYearSelection: Partial<BsDatepickerConfig>;
  datePickerConfigwithYearSelection: Partial<BsDatepickerConfig> = {
    dateInputFormat: 'MM/YYYY', // Display only Month & Year
    minMode: 'year', // Start selection from year
    showWeekNumbers: false,
    isAnimated: true,
    containerClass: 'theme-dark-blue',
  };

  billingType: any;
  monthsList: any;
  dupMonthsList: any;
  monthsListWithAll = Months;
  dupMonthsListWithAll = Months;
  projectId: string;
  flatnumbersList: any = [];
  flatnumbersListDup: any = [];
  walletBanlanceFalttemList: any[] = []
  billwise_selectedFlatNumber: any = ['all'];
  selectActivationStatus: any = 'all';
  select_Status: any = 'all';
  selecteddeviceCommunicationMcb: any = 'all';
  selectedValve_Status: any = 'all';
  selectedValve_Status_MeterReadings: string = 'all'
  selectedBill_Status: string = 'all'
  selectedCommnunicationStatus: any = 'all';
  deviceCommunication_selectedFlatNumber: any = ['all'];
  meterReading_selectedFlatNumber: any = ['all'];
  billGeneration_selectedFlatNumber: any = ['all'];
  Consumption_selectedFlatNumber: any = ['all'];
  dailyConsumption_selectedFlatNumber: any = ['all'];
  wallet_selectedFlatNumber: any = ['all'];
  wallet_FlatNumber: any = ['all'];
  selected_walletbalance: any = ['all'];
  selectedMonthsForWalletBalanceReport: any = ['all'];
  selectedMonth: any;
  exportActivationStatusForm!: UntypedFormGroup;
  submittedActivationStatus: boolean = false;
  exportActivationStatusFormValidations = Validations.exportActivationStatusFormValidations;
  utilityValidationMessage: string = '';
  selectActivationStatusValidationMessage: string = ''
  consumptionDateRangeValidationMessage: string = ''
  dailyutilityValidationMessage: String = ''
  dateRangeValidationMessage: string = '';
  DeviceCommunicationdateRangeValidationMessage: string = ''

  waleyHistorydateRangeValidationMessage: string = '';

  consymerWalletdateRangeValidationMessage: string = '';
  billgendateRangeValidationMessage: string = '';
  meterreadingdateRangeValidationMessage: string = '';
  dailydateRangeValidationMessage: string = '';
  consumption_selectedRange: string = ''
  dateRangeSettlementValidationMessage: string = '';

  flatNumberValidationMessage: string = '';
  monthValidationMessage: string = '';
  Active_Status = Active_Status;
  Activation_Status = Activation_Status;
  Mcb_Status = Mcb_Status;
  Valve_Status = Valve_Status;
  Communication_Status = Communication_Status;
  BillStatis = BillStatus;
  WalletBalance_Status = WalletBalanceStatus;
  ProjectsValidationMessage: string = '';
  ProjectValidationMessage: string = '';
  ProjectSettlmentValidationMessage: string = '';
  isDeviceCommunication: boolean = false;
  iswalletBalnceReport: boolean = false;
  iswalleHistoryReportOpen: boolean = false;
  walletStatus: boolean = false;
  isConsumptionOpen: boolean = false
  consumptionlistFromdashboard: any = {}
  hidePreviouseMeteravailableBtn: boolean = false;
  ismeterDownloadignInprogress: boolean = false;
  isMeterDownloadedLinkAvailable: boolean = false;
  meterfileUrl: any;
  meterReports: any = []
  userId: any = '';
  downloadMeterSetinterval: any;
  statusMeterReadingSetinterval: any;
  BatteryLevelutilityList: any;
  BatteryLevelutilityListDup: any = [];

  consumptionReports: any[] = []; // Stores filtered consumption reports
  consumptionReportfileUrl: any = null; // Stores the report download link

  isConsumptionDownloadignInprogress: boolean = false; // Report generating flag
  isConsumptionDownloadedLinkAvailable: boolean = false; // Report available flag
  hidePreviouseConsumptionavailableBtn: boolean = false; // Controls button visibility



  deviceCommunicationReports: any[] = []; // Stores device communication reports
  deviceCommunicationfileUrl: any = null; // Stores the report download link

  isDeviceCommunicationDownloadignInprogress: boolean = false; // Flag for report generation status
  isDeviceCommunicationDownloadedLinkAvailable: boolean = false; // Flag for report availability
  hidePreviouseDeviceCommunicationReportavailableBtn: boolean = false; // Controls visibility of the previous report button

  constructor(
    private router: Router,
    private store: Store,
    private loaderService: LoaderService,
    private sharedService: SharedService,
    private reportService: ReportsService,
    private faqService: FaqService,
    private fb: FormBuilder
  ) {
    this.breadCrumbItems = [{ label: `Reports` }];
    const currentUserString = localStorage.getItem('currentUser');
    let currentUserData = JSON.parse(currentUserString ?? '');
    this.role = currentUserData?.currentUserRoleDetails?.role;
    this.billingType = currentUserData?.project?.billingType;
    this.projectId = currentUserData?.project?.id;
    this.walletStatus = currentUserData?.project?.isWalletEnabled;
    this.userId = currentUserData?.currentUserRoleDetails?.userId;
    let today = new Date();
    let sixMonthsAgo = new Date();
    if (this.projectId) {

      // currentUserData.project.createdOn="2024-12-01T00:00:00"
      // const dateon = new Date('Sun Jun 15 2024 00:00:00 GMT+0530 (India Standard Time)'); // Parse the date string
      const dateon = new Date(currentUserData.project.startDate); // Parse the date string    
      let monthsDifference =
        today.getFullYear() * 12 + today.getMonth() - (dateon.getFullYear() * 12 + dateon.getMonth());
      if (monthsDifference > 6) {
        monthsDifference = 6;
        sixMonthsAgo.setMonth(today.getMonth() - monthsDifference);
      } else {
        sixMonthsAgo = dateon
      }

    } else {
      sixMonthsAgo.setMonth(today.getMonth() - 6);


    }
    this.deviceCommunicationDateRange[0] = sixMonthsAgo;
    this.deviceCommunicationDateRange[1] = today;
    this.datePickerConfig = Object.assign({
      // dateInputFormat: 'YYYY-MM-DD', // Customize as needed
      rangeInputFormat: 'DD/MM/YYYY',
      showWeekNumbers: false,
      isAnimated: true,
      containerClass: 'theme-dark-blue',
      minDate: sixMonthsAgo, // Set minimum date to 6 months ago
      maxDate: today // Set maximum date to today
    });

    const thirtyDaysAgo = new Date();
    thirtyDaysAgo.setDate(today.getDate() - 30);

    this.meterReadingsReportDates = Object.assign({
      // dateInputFormat: 'YYYY-MM-DD', // Customize as needed
      rangeInputFormat: 'DD/MM/YYYY',
      showWeekNumbers: false,
      isAnimated: true,
      containerClass: 'theme-dark-blue',
      minDate: thirtyDaysAgo, // Set minimum date to 6 months ago
      maxDate: today // Set maximum date to today
    })




    if (this.projectId) {
      this.getAllFlatNumbers();
    }

    if (history.state.consumptionItem != undefined && history.state.consumptionItem !== null) {
      this.consumptionlistFromdashboard = history.state.consumptionItem
      this.isConsumptionOpen = true;
      console.log(this.consumptionlistFromdashboard)
    }

    this.getLastSixMonths();
    this.getLastSixMonthsWithAll();
  }

  getLastSixMonths(): void {
    const currentDate = new Date();
    const monthsWithYears: string[] = [];

    for (let i = 0; i < 6; i++) {
      const tempDate = new Date();
      tempDate.setMonth(currentDate.getMonth() - i); // Go back i months

      const monthIndex = tempDate.getMonth(); // Get month index (0-11)
      const monthName = Months[monthIndex]?.name; // Get the 'name' property from Months array
      const year = tempDate.getFullYear(); // Get year

      monthsWithYears.push(`${monthName}-${year}`);
    }

    this.monthsList = monthsWithYears;
    console.log('Months List:', this.monthsList);

    this.dupMonthsList = [...monthsWithYears];
  }


  getLastSixMonthsWithAll(): void {
    const currentDate = new Date();
    const currentMonth = currentDate.getMonth() - 1; // 0-11

    // Calculate the next 5 months including the current month
    let nextFiveMonths = [];
    for (let i = 0; i < 6; i++) {
      const monthIndex = (currentMonth - i + 12) % 12;
      nextFiveMonths.push(Months[monthIndex]);
    }
    nextFiveMonths = [{
      id: 'all',
      name: 'All',
    }, ...nextFiveMonths]
    this.monthsListWithAll = nextFiveMonths;
    this.dupMonthsListWithAll = nextFiveMonths;
  }


  ngOnInit() {
    if (this.role == (this.listOfRoles.ADMIN)) {
      // this.getAllFlatNumbers();
      this.getLatestMeterReadingsReportStatus('initcheck');
      this.getLatestConsumptionReadingsReportStatus('initcheck');
      this.getLatestDeviceCommunicationReportStatus('initcheck');
      this.getAllConfigData();
    } else {
      this.getAllConfigData();
    }
    const currentDate = new Date();
    const sixMonthsAgo = new Date();
    sixMonthsAgo.setMonth(currentDate.getMonth() - 6);


  }

  ngOnDestroy() {
    if (this.downloadMeterSetinterval) {
      clearInterval(this.downloadMeterSetinterval); // Clear the interval
      //   console.log('Interval cleared');
    }
    if (this.statusMeterReadingSetinterval) {
      clearInterval(this.statusMeterReadingSetinterval); // Clear the interval
      //  console.log('Interval cleared');
    }
  }


  getLatestMeterReadingsReportStatus(type: string) {
    this.reportService.getLatestMeterReadingsReportStatusService(this.userId, this.projectId, 'MeterReading Report').subscribe({
      next: (response: any) => {
        if (response && response?.statusCode == 200) {
          console.log(response);
          this.meterReports = response?.data
            .filter((x: any) => x.reportName === 'MeterReading Report')
            .flatMap((x: any) => x.reports);

          if (this.meterReports.length > 0) {
            this.hidePreviouseMeteravailableBtn = false;
            this.ismeterDownloadignInprogress = this.meterReports.some((x: any) => x.isReportGenerating);
            this.isMeterDownloadedLinkAvailable = this.meterReports.some((x: any) => !x.isReportGenerating);
            this.meterfileUrl = this.meterReports.find((x: any) => !x.isReportGenerating);

            if ((type == 'download' || type == 'getStatus') && !this.ismeterDownloadignInprogress) {
              this.sharedService.showMessageDialog(
                'Report Generation Completed',
                'Please Click Generated Report to get excel file',
                SweetAlertIcon.INFO
              )
              // this.sharedService.downloadUtilityFiles(this.meterfileUrl.name, 'meterreading.xlsx');
            } else if ((type == 'getStatus' || type == 'download') && this.ismeterDownloadignInprogress) {

              this.sharedService.showMessageDialog(
                'Report Generation In Progress',
                'The requested report is currently being generated. Once completed, you can download it by clicking on the <strong>"Generated Report',
                SweetAlertIcon.INFO
              )
            }

          } else {
            this.hidePreviouseMeteravailableBtn = true;
          }
          this.loaderService.setLoading(false);
        }
      }
    })

  }

  downloadPreviousMeterReportFile() {

    if (this.meterfileUrl.status == "Success") {
      this.sharedService.downloadUtilityFiles(this.meterfileUrl.name, 'meterreading.xlsx');
    } else {
      this.sharedService.showMessageDialog(
        'Report Export Failed',
        'The report failed to export. Please try again.',
        SweetAlertIcon.ERROR
      );
    }

  }

  downloadPreviousConsumptionReportFile() {
    if (this.consumptionReportfileUrl.status == "Success") {
      this.sharedService.downloadUtilityFiles(this.consumptionReportfileUrl.name, this.consumptionReportfileUrl.reportType + ' Report.xlsx');
    } else {
      this.sharedService.showMessageDialog(
        'Report Export Failed',
        'The report failed to export. Please try again.',
        SweetAlertIcon.ERROR
      );
    }

  }

  downloadPreviousDeviceCommunicationReportFile() {

    if (this.deviceCommunicationfileUrl.status == "Success") {
      this.sharedService.downloadUtilityFiles(this.deviceCommunicationfileUrl.name, 'deviceCommunicationReport.xlsx');
    } else {
      this.sharedService.showMessageDialog(
        'Report Export Failed',
        'The report failed to export. Please try again.',
        SweetAlertIcon.ERROR
      );
    }

  }


  getAllFlatNumbers() {
    this.loaderService.setLoading(true);
    this.reportService.getFlatNumbersByProjectId(this.projectId).subscribe({
      next: (response) => {
        if (response && response?.statusCode == 200) {
          response.data = [{
            id: 'all',
            flatNo: 'All Flats',
          }, ...response.data]

          this.flatnumbersList = response?.data;
          this.flatnumbersListDup = response?.data;
          this.walletBanlanceFalttemList = response?.data;
          this.loaderService.setLoading(false);
        }
      }
    })
  }

  getAllConfigData(): void {
    this.loaderService.setLoading(true);
    this.sharedService.getAllConfigData().subscribe((data) => {

      // setTimeout(()=>{
      this.loaderService.setLoading(false);
      //   alert()
      // },2000)
      if (
        data.statusCode == 200 &&
        data.message == 'Config data retrieved successfully.'
      ) {
        this.configData = data.data;
        this.configData.utilities = [
          {
            id: 'all',
            name: 'All Utilities',
          },
          ...(data.data?.utilities || []), // Use an empty array if data.data.utilities is undefined
        ];

        this.configData.projects = [
          {
            id: 'all',
            name: 'All Projects',
          },
          ...(data.data?.projects || [])
        ];




        this.projectsDupList = [
          // {
          //   id: 'all',
          //   name: 'All Projects',
          // },
          ...this.configData?.projects,
        ];


        this.projectsList = [
          // {
          //   id: 'all',
          //   name: 'All Projects',
          // },
          ...this.configData?.projects,
        ];
        this.utilityList = [
          ...this.configData?.utilities,
        ];

        this.utilitylistDup = [
          ...this.configData?.utilities,
        ];

        this.BatteryLevelutilityList = this.configData?.utilities.filter((item: any) => item.name !== "Electricity");

        this.BatteryLevelutilityListDup = [...this.configData?.utilities.filter((item: any) => item.name !== "Electricity")];

        // console.log(this.utilityList)
        this.selectedProject = [this.projectsList[0].id];
        this.selectedSettlementProject = [this.projectsList[0].id];
        this.selectedUtility = [this.utilityList[0].id];
        this.selectedBatteryLevel = 'all';

        if (Object.keys(this.consumptionlistFromdashboard).length > 0) {
          if (this.consumptionlistFromdashboard.type == 'top5Consumption') {
            console.log(this.consumptionlistFromdashboard)
            // Assuming the startDate and endDate are already Date objects
            this.consumptionlistFromdashboard.date.startDate = new Date(this.consumptionlistFromdashboard.date.startDate);
            this.consumptionlistFromdashboard.date.endDate = new Date(this.consumptionlistFromdashboard.date.endDate);
            this.consumption_selectedRange = 'Day Wise';
            // Format the dates as 'yyyy-MM-dd'
            let startDateFormatted = this.consumptionlistFromdashboard.date.startDate.toISOString().split('T')[0];
            let endDateFormatted = this.consumptionlistFromdashboard.date.endDate.toISOString().split('T')[0];

            // Now set the formatted date back to your object
            this.consumptionlistFromdashboard.date.startDate = startDateFormatted;
            this.consumptionlistFromdashboard.date.endDate = endDateFormatted;

            //console.log(this.consumptionlistFromdashboard.date);

            this.utilityList.forEach((item: any) => {
              if (item.name == this.consumptionlistFromdashboard.UtilityType) {
                this.consumption_selectedUtility = [item.id];
                this.dailyconsumption_selectedUtility = [item.id]
              }
            })
            // console.log(this.consumptionlistFromdashboard)
            // this.consumptionDateRange = this.consumptionlistFromdashboard.date;

            this.consumptionDateRange = [
              new Date(this.consumptionlistFromdashboard.date.startDate),
              new Date(this.consumptionlistFromdashboard.date.endDate),
            ];

            /* this.dailyconsumptionDateRange = [
              new Date(this.consumptionlistFromdashboard.date.startDate),
              new Date(this.consumptionlistFromdashboard.date.endDate),
            ]; */


            let FlatNo = this.consumptionlistFromdashboard.flatNo;
            console.log(this.flatnumbersList, FlatNo);
            this.flatnumbersList.forEach((data: any) => {
              if (data.flatNo == this.consumptionlistFromdashboard.flatNo) {
                // console.log(data);
                this.Consumption_selectedFlatNumber = [data.id];
                // console.log(this.Consumption_selectedFlatNumber);
              }
            })
          } else if (this.consumptionlistFromdashboard.type == 'Consumption') {
            this.utilityList.forEach((item: any) => {
              if (item.name == this.consumptionlistFromdashboard.UtilityType) {
                this.consumption_selectedUtility = [item.id];
                this.dailyconsumption_selectedUtility = [item.id]
                this.consumption_selectedRange = 'Day Wise';
              }
            })

            //  console.log(this.consumptionlistFromdashboard);
            const transformData = (data: any) => {
              const [timestamp, amount] = data.value; // Destructure value array

              // Create a new object
              return {
                UtilityType: data.UtilityType,
                flag: data.flag,
                value: amount,
                /*   value: {
                    amount: amount,
                    timestamp: timestamp
                  }, */
                date: new Date(timestamp).toISOString().split('T')[0] // Format date as YYYY-MM-DD
              };
            };
            const dt = transformData(this.consumptionlistFromdashboard);
            this.consumptionDateRange = [
              new Date(dt.date),
              new Date(dt.date),
            ];

          } else if (this.consumptionlistFromdashboard.type == 'Meter') {
            const today = new Date();
            this.deviceCommunicationDateRange[0] = today;
            this.deviceCommunicationDateRange[1] = today;

            this.deviceCommunication_selectedUtility = [this.consumptionlistFromdashboard?.utility]

            //  console.log("utukdhskhdskdsdksadksada---->", this.consumptionlistFromdashboard);
            this.isDeviceCommunication = true;

            switch (this.consumptionlistFromdashboard.value) {

              case 'Inactive':
                this.selectActivationStatus = 'In-active'
                this.selectedCommnunicationStatus = 'No'
                break
              case 'MCB Off':
                this.selecteddeviceCommunicationMcb = 'Off'
                break
              case 'Communicating':
                this.selectedCommnunicationStatus = 'Yes'
                this.selectActivationStatus = 'Active'
                break
              case 'Non Communicating':
                this.selectedValve_Status = 'Off'
                this.selectedCommnunicationStatus = 'No'
                this.selectActivationStatus = 'Active'

                break
            }

          } else if (this.consumptionlistFromdashboard.type == 'Total Wallet Recharge') {
            this.iswalletBalnceReport = true;

            const today = new Date();
            this.walletBalanceDateRange[0] = new Date(this.consumptionlistFromdashboard.projectStartDate);
            this.walletBalanceDateRange[1] = today;
            this.selected_walletbalance = ['Success']
          } else if (this.consumptionlistFromdashboard.type == 'Total Wallet History') {
            this.iswalleHistoryReportOpen = true; // Wallet History Reports
            this.iswalletBalnceReport = false;  // Consumer wallet Reports
            const today = new Date();
            this.walletHistoryDateRange[0] = new Date(this.consumptionlistFromdashboard.projectStartDate);
            this.walletHistoryDateRange[1] = today;
          }


        }



      }
    });
  }

  public onProjectSettlementFilterChange(searchTerm: any): void {
    const contains = (value: string) => (item: { name: string; id: number }) =>
      item.name.toLowerCase().includes(value.toLowerCase());

    this.projectsList = this.projectsDupList.filter(contains(searchTerm));
  }

  public onProjectFilterChange(searchTerm: any): void {
    const contains = (value: string) => (item: { name: string; id: number }) =>
      item.name.toLowerCase().includes(value.toLowerCase());

    this.projectsList = this.projectsDupList.filter(contains(searchTerm));
  }

  public onUtilityFilterChange(searchTerm: any): void {
    const contains = (value: string) => (item: { name: string; id: number }) =>
      item.name.toLowerCase().includes(value.toLowerCase());

    this.utilityList = this.configData?.utilities.filter(contains(searchTerm));
  }



  onUtilityValueChange(value: any): void {
    if (value.length > 0) {
      let selectAlldata = value.filter((x: any) => {
        return x.id == 'selectAll';
      });
      if (selectAlldata.length > 0) {
        this.selectedUtility = this.configData?.utilities;
      }
    } else {
      this.selectedUtility = [];
      this.isUtilityChecked = false;
    }
  }

  // onBatteryValueChange(value: any): void {
  //   console.log('valueChange', value);
  //   if (value.length > 0) {
  //     let selectAlldata = value.filter((x: any) => {
  //       return x.id == 'selectAll';
  //     });
  //     if (selectAlldata.length > 0) {
  //       this.selectedBatteryLevel = this.batteryLevel;
  //     }
  //   } else {
  //     this.selectedBatteryLevel = [];
  //     this.isBatteryChecked = false;
  //   }
  // }

  export() {
    // Reset validation messages

    this.utilityValidationMessage = '';
    this.dailyutilityValidationMessage = ''
    this.ProjectsValidationMessage = '';

    let isValid = true;

    if (!this.selectedUtility || this.selectedUtility.length === 0) {
      this.utilityValidationMessage = 'Please select at least one utility.';
      isValid = false;
    }

    if (!this.selectedProject || this.selectedProject.length === 0) {
      this.ProjectValidationMessage = 'Please select at least one Project.';
      isValid = false;
    }





    if (isValid) {
      this.loaderService.setLoading(true)
      let payload = {

        BatteryStatus:
          this.selectedBatteryLevel,
        "Utility": this.selectedUtility && this.selectedUtility.length > 0
          ? (this.selectedUtility[0] === 'all' ? null : this.selectedUtility)
          : null, // Handling cases where selectedUtility is empty or "all"
        projectIds: this.selectedProject && this.selectedProject.length > 0
          ? (this.selectedProject[0] === 'all' ? null : this.selectedProject)
          : null, // Handling cases where selectedUtility is empty or "all"
      }
      this.reportService.exportReport(payload, 'BatteryStatus');
    };


  }

  onInputChange() {
    // Clear individual validation messages when user interacts

    if (this.selectedUtility.length > 0) {
      this.utilityValidationMessage = '';
    }
    if (this.activationDateRange.length > 0) {
      this.dateRangeValidationMessage = '';
    }
  }

  onValueChange(selectedDate: Date | undefined) {
    if (selectedDate) {
      // console.log("Selected Year:", selectedDate.getFullYear());
      // console.log("Selected Month:", selectedDate.getMonth() + 1); // Months are 0-indexed

      // After selecting a year, switch to month selection
      this.datePickerConfigwithYearSelection = {
        ...this.datePickerConfigwithYearSelection,
        minMode: 'month',
        minDate: this.getLastSixMonthsDate(), // Ensure only last 6 months can be selected
        maxDate: new Date(),
      };
    }
  }

  getLastSixMonthsDate(): Date {
    const today = new Date();
    return new Date(today.getFullYear(), today.getMonth() - 6, 1); // First day of 6 months ago
  }








  exportSettlemet() {
    let isValid = true;
    this.ProjectSettlmentValidationMessage = '';
    this.dateRangeSettlementValidationMessage = '';
    if (this.settlemetDateRange.length === 0) {
      this.dateRangeSettlementValidationMessage = 'Please select a date range.';
      isValid = false;
    }
    if (!this.selectedSettlementProject || this.selectedSettlementProject.length === 0) {
      this.ProjectSettlmentValidationMessage = 'Please select at least one Project.';
      isValid = false;
    }

    if (isValid) {
      this.loaderService.setLoading(true)
      const fromDate = new Date(this.settlemetDateRange[0].getTime() - this.settlemetDateRange[0].getTimezoneOffset() * 60000);
      const toDate = new Date(this.settlemetDateRange[1].getTime() - this.settlemetDateRange[1].getTimezoneOffset() * 60000);

      let payload = {
        "fromDate": fromDate.toISOString().slice(0, 10), // Extracting "YYYY-MM-DD"
        "toDate": toDate.toISOString().slice(0, 10),     // Extracting "YYYY-MM-DD"
        "projectId": this.selectedSettlementProject == 'all' ? null : this.selectedSettlementProject
      };
      this.reportService.exportReport(payload, 'Settlment');
    }
  }

  exportActionStatus() {
    this.utilityValidationMessage = '';
    this.dateRangeValidationMessage = '';

    let isValid = true;

    if (!this.selectedUtility || this.selectedUtility.length === 0) {
      this.utilityValidationMessage = 'Please select at least one utility.';
      isValid = false;
    }

    // if (this.activationDateRange.length === 0) {
    //   this.dateRangeValidationMessage = 'Please select a date range.';
    //   isValid = false;
    // }





    this.submittedActivationStatus = true;
    if (isValid) {
      let fromDate = null;
      let toDate = null;


      this.submittedActivationStatus = false;
      this.loaderService.setLoading(true)

      if (this.activationDateRange.length > 0) {
        fromDate = new Date(this.activationDateRange[0].getTime() - this.activationDateRange[0].getTimezoneOffset() * 60000);
        toDate = new Date(this.activationDateRange[1].getTime() - this.activationDateRange[1].getTimezoneOffset() * 60000);

      }

      let payload = {
        "Active": this.select_Status,
        "fromDate": fromDate ? fromDate.toISOString().slice(0, 10) : null, // "YYYY-MM-DD" or null
        "toDate": toDate ? toDate.toISOString().slice(0, 10) : null,
        "utilityMasterId": this.selectedUtility && this.selectedUtility.length > 0
          ? (this.selectedUtility[0] === 'all' ? null : this.selectedUtility)
          : null, // Handling cases where selectedUtility is empty or "all"
        "projectId": this.projectId
      };
      this.reportService.exportReport(payload, 'activation');
    }

  }

  exportConsumptionReport() {
    this.utilityValidationMessage = '';  // Resetting utility validation message
    this.dateRangeValidationMessage = ''; // Resetting date range validation message
    // this.flatNumberValidationMessage = ''; // Resetting flat number validation message

    let isValid = true;

    // Validate selected utilities
    if (!this.consumption_selectedUtility || this.consumption_selectedUtility.length === 0) {
      this.utilityValidationMessage = 'Please select at least one utility.';
      isValid = false;
    }
    if (this.consumption_selectedRange == '') {
      this.consumptionDateRangeValidationMessage = 'Please select Range.';
      isValid = false;
    } else {
      this.consumptionDateRangeValidationMessage = ''
    }


    // Validate selected date range
    if (this.consumptionDateRange.length === 0) {
      this.dateRangeValidationMessage = 'Please select a date range.';
      isValid = false;
    } else {
      this.dateRangeValidationMessage = ''
    }

    // Validate selected flat numbers
    /*  if (!this.Consumption_selectedFlatNumber || this.Consumption_selectedFlatNumber.length === 0) {
       this.flatNumberValidationMessage = 'Please select at least one flat number.';
       isValid = false;
     } */

    alert(this.consumption_selectedRange)

    if (isValid) {
      this.loaderService.setLoading(true);

      const fromDate = new Date(this.consumptionDateRange[0].getTime() - this.consumptionDateRange[0].getTimezoneOffset() * 60000);
      const toDate = new Date(this.consumptionDateRange[1].getTime() - this.consumptionDateRange[1].getTimezoneOffset() * 60000);

      let payload = {
        "consumption_Range": this.consumption_selectedRange,
        "fromDate": fromDate.toISOString().slice(0, 10), // Extracting "YYYY-MM-DD"
        "toDate": toDate.toISOString().slice(0, 10),     // Extracting "YYYY-MM-DD"
        "utilityMasterId": this.consumption_selectedUtility && this.consumption_selectedUtility.length > 0
          ? (this.consumption_selectedUtility[0] === 'all' ? null : this.consumption_selectedUtility)
          : null, // Handling cases where selectedUtility is empty or "all"
        "projectId": this.projectId,
        "residentialUnitId": this.Consumption_selectedFlatNumber && this.Consumption_selectedFlatNumber.length > 0
          ? (this.Consumption_selectedFlatNumber[0] === 'all' ? null : this.Consumption_selectedFlatNumber)
          : null,
        "userid": this.userId
      };
      // this.reportService.exportReport(payload, 'consumption');



      this.reportService.exportReportMeterReadings(payload, 'consumption').subscribe(
        (response: any) => {
          if (response.statusCode == 200) {
            this.getLatestConsumptionReadingsReportStatus('download')
            // console.log(response.message); // "Report downloaded successfully."
          } else {
            //  console.log(response.message); // "No Data Found." or any other message
          }
        },
        (error: any) => {
          console.error('Error while exporting report:', error);
        }
      );



    }
  }


  getLatestConsumptionReadingsReportStatus(type: string) {
    this.reportService.getLatestMeterReadingsReportStatusService(this.userId, this.projectId, 'Consumption-Report').subscribe({
      next: (response: any) => {
        if (response && response?.statusCode == 200) {
          console.log(response);
          this.consumptionReports = response?.data
            .filter((x: any) => x.reportName === 'Consumption-Report')
            .flatMap((x: any) => x.reports);

          if (this.consumptionReports.length > 0) {
            this.hidePreviouseConsumptionavailableBtn = false;
            this.isConsumptionDownloadignInprogress = this.consumptionReports.some((x: any) => x.isReportGenerating);
            this.isConsumptionDownloadedLinkAvailable = this.consumptionReports.some((x: any) => !x.isReportGenerating);
            this.consumptionReportfileUrl = this.consumptionReports.find((x: any) => !x.isReportGenerating);

            if ((type == 'download' || type == 'getStatus') && !this.isConsumptionDownloadignInprogress) {
              this.sharedService.showMessageDialog(
                'Report Generation Completed',
                'Please Click Generated Report to get excel file',
                SweetAlertIcon.INFO
              )
              // this.sharedService.downloadUtilityFiles(this.meterfileUrl.name, 'meterreading.xlsx');
            } else if ((type == 'getStatus' || type == 'download') && this.isConsumptionDownloadignInprogress) {

              this.sharedService.showMessageDialog(
                'Report Generation In Progress',
                'The requested report is currently being generated. Once completed, you can download it by clicking on the <strong>"Generated Report',
                SweetAlertIcon.INFO
              )
            }

          } else {
            this.hidePreviouseConsumptionavailableBtn = true;
          }
          this.loaderService.setLoading(false);
        }
      }
    })

  }




  exportDailyConsumptionReport() {


    this.dailyutilityValidationMessage = '';  // Resetting utility validation message
    this.dailydateRangeValidationMessage = ''; // Resetting date range validation message


    let isValid = true;

    // Validate selected utilities
    if (!this.dailyconsumption_selectedUtility || this.dailyconsumption_selectedUtility.length === 0) {
      this.dailyutilityValidationMessage = 'Please select at least one utility.';
      isValid = false;
    }

    // Validate selected date range
    if (this.dailyconsumptionDateRange.length === 0) {
      this.dailydateRangeValidationMessage = 'Please select a date range.';
      isValid = false;
    }

    if (isValid) {
      this.loaderService.setLoading(true);

      const fromDate = new Date(this.dailyconsumptionDateRange[0].getTime() - this.dailyconsumptionDateRange[0].getTimezoneOffset() * 60000);
      const toDate = new Date(this.dailyconsumptionDateRange[1].getTime() - this.dailyconsumptionDateRange[1].getTimezoneOffset() * 60000);

      let payload = {
        "fromDate": fromDate.toISOString().slice(0, 10), // Extracting "YYYY-MM-DD"
        "toDate": toDate.toISOString().slice(0, 10),     // Extracting "YYYY-MM-DD"
        "utilityMasterId": this.dailyconsumption_selectedUtility && this.dailyconsumption_selectedUtility.length > 0
          ? (this.dailyconsumption_selectedUtility[0] === 'all' ? null : this.dailyconsumption_selectedUtility)
          : null, // Handling cases where selectedUtility is empty or "all"
        "projectId": this.projectId,
        "residentialUnitId": this.dailyConsumption_selectedFlatNumber && this.dailyConsumption_selectedFlatNumber.length > 0
          ? (this.dailyConsumption_selectedFlatNumber[0] === 'all' ? null : this.dailyConsumption_selectedFlatNumber)
          : null,
      };
      this.reportService.exportReport(payload, 'daily Consumption');
    }
  }


  exportMonthWiseWalletBalnceReports() {
    this.monthValidationMessage = '';     // Resetting month validation message
    let isValid = true;
    //  console.log(this.selectedMonthsForWalletBalanceReport)
    // Validate selected month
    if (this.selectedMonthsForWalletBalanceReport.length == 0) {
      this.monthValidationMessage = 'Please select a month.';
      isValid = false;
      return
    }

    if (isValid) {
      this.loaderService.setLoading(true);

      const payload = {
        month: this.selectedMonth,
        "months": this.selectedMonthsForWalletBalanceReport && this.selectedMonthsForWalletBalanceReport.length > 0
          ? (this.selectedMonthsForWalletBalanceReport[0] === 'all' ? null : this.selectedMonthsForWalletBalanceReport)
          : null, // Handling cases where selectedUtility is empty or "all"
        "projectId": this.projectId,
      };
      this.reportService.exportReport(payload, 'monthwiseBalance');
    }

  }


  exportBillWiseConsumptionReports() {


    this.utilityValidationMessage = '';  // Resetting utility validation message
    this.monthValidationMessage = '';     // Resetting month validation message
    this.flatNumberValidationMessage = ''; // Resetting flat number validation message

    let isValid = true;

    // Validate selected utilities
    if (!this.billwise_selectedUtility || this.billwise_selectedUtility.length === 0) {
      this.utilityValidationMessage = 'Please select at least one utility.';
      isValid = false;
    }

    // Validate selected month
    if (!this.selectedMonth) {
      this.monthValidationMessage = 'Please select a month.';
      isValid = false;
    }

    // Validate selected flat numbers
    if (!this.billwise_selectedFlatNumber || this.billwise_selectedFlatNumber.length === 0) {
      this.flatNumberValidationMessage = 'Please select at least one flat number.';
      isValid = false;
    }

    if (isValid) {
      this.loaderService.setLoading(true);

      const payload = {
        month: this.selectedMonth,
        "utilityMasterId": this.billwise_selectedUtility && this.billwise_selectedUtility.length > 0
          ? (this.billwise_selectedUtility[0] === 'all' ? null : this.billwise_selectedUtility)
          : null, // Handling cases where selectedUtility is empty or "all"
        "projectId": this.projectId,
        "residentialUnitId": this.billwise_selectedFlatNumber && this.billwise_selectedFlatNumber.length > 0
          ? (this.billwise_selectedFlatNumber[0] === 'all' ? null : this.billwise_selectedFlatNumber)
          : null,
      };



      // this.reportService.exportReport(payload, 'postpaid');
      this.reportService.exportReport(payload, this.billingType === 'Prepaid' ? 'Invoice_Consumption_Reports' : 'Bill_Wise_Consumption_Reports');

    }
  }


  exportDeviceCommunicationReports() {
    this.utilityValidationMessage = '';  // Resetting utility validation message
    this.dateRangeValidationMessage = '';     // Resetting month validation message
    this.flatNumberValidationMessage = ''; // Resetting flat number validation message

    let isValid = true;

    // Validate selected utilities
    if (!this.deviceCommunication_selectedUtility || this.deviceCommunication_selectedUtility.length === 0) {
      this.utilityValidationMessage = 'Please select at least one utility.';
      isValid = false;
    }

    if (!this.selectActivationStatus) {
      this.selectActivationStatusValidationMessage = 'Please select Active.';
      isValid = false;
    } else {
      this.selectActivationStatusValidationMessage = ''
    }

    // Validate selected month
    /*  if (!this.deviceCommunicationDateRange || this.deviceCommunicationDateRange.length == 0) {
       this.dateRangeValidationMessage = 'Please select a date range.';
       isValid = false;
     } */

    // Validate selected flat numbers
    if (!this.deviceCommunication_selectedFlatNumber || this.deviceCommunication_selectedFlatNumber.length === 0) {
      this.flatNumberValidationMessage = 'Please select at least one flat number.';
      isValid = false;
    }

    if (!this.deviceCommunicationDateRange || this.deviceCommunicationDateRange.length === 0) {
      this.DeviceCommunicationdateRangeValidationMessage = 'Please select Date Range.';
      isValid = false;
    } else {
      this.DeviceCommunicationdateRangeValidationMessage = ''
    }


    if (isValid) {
      this.loaderService.setLoading(true);

      let fromDate = null;
      let toDate = null;

      // Check if deviceCommunicationDateRange is available and set dates

      if (this.deviceCommunicationDateRange.length > 0) {
        fromDate = new Date(this.deviceCommunicationDateRange[0].getTime() - this.deviceCommunicationDateRange[0].getTimezoneOffset() * 60000);
        toDate = new Date(this.deviceCommunicationDateRange[1].getTime() - this.deviceCommunicationDateRange[1].getTimezoneOffset() * 60000);




      }
      // If fromDate or toDate is not set, use the current date or another default value
      /*  if (!fromDate) {
         fromDate = null; // Default to current date or handle as needed
       }
       if (!toDate) {
         toDate = null; // Default to current date or handle as needed
       } */
      let payload = {
        "fromDate": fromDate !== null ? fromDate?.toISOString().slice(0, 10) : null, // Extracting "YYYY-MM-DD"
        "toDate": toDate !== null ? toDate?.toISOString().slice(0, 10) : null,     // Extracting "YYYY-MM-DD"
        "utilityMasterId": this.deviceCommunication_selectedUtility && this.deviceCommunication_selectedUtility.length > 0
          ? (this.deviceCommunication_selectedUtility[0] === 'all' ? null : this.deviceCommunication_selectedUtility)
          : null, // Handling cases where selectedUtility is empty or "all"
        "projectId": this.projectId,
        "residentialUnitId": this.deviceCommunication_selectedFlatNumber && this.deviceCommunication_selectedFlatNumber.length > 0
          ? (this.deviceCommunication_selectedFlatNumber[0] === 'all' ? null : this.deviceCommunication_selectedFlatNumber)
          : null,
        "active": this.selectActivationStatus,
        // "mcbStatus": this.selecteddeviceCommunicationMcb,
        // "valveStatus": this.selectedValve_Status,
        "communicatingStatus": this.selectedCommnunicationStatus,
        "userid": this.userId
      };

      // this.reportService.exportReport(payload, 'DeviceCommunications');


      this.reportService.exportReportMeterReadings(payload, 'DeviceCommunications').subscribe(
        (response: any) => {
          if (response.statusCode == 200) {
            this.getLatestDeviceCommunicationReportStatus('download')
            // console.log(response.message); // "Report downloaded successfully."
          } else {
            //  console.log(response.message); // "No Data Found." or any other message
          }
        },
        (error) => {
          console.error('Error while exporting report:', error);
        }
      );

    }
  }


  getLatestDeviceCommunicationReportStatus(type: string) {
    this.reportService.getLatestMeterReadingsReportStatusService(this.userId, this.projectId, 'Device-Communication').subscribe({
      next: (response: any) => {
        if (response && response?.statusCode == 200) {
          console.log(response);
          this.deviceCommunicationReports = response?.data
            .filter((x: any) => x.reportName === 'Device-Communication')
            .flatMap((x: any) => x.reports);

          if (this.deviceCommunicationReports.length > 0) {
            this.hidePreviouseDeviceCommunicationReportavailableBtn = false;
            this.isDeviceCommunicationDownloadignInprogress = this.deviceCommunicationReports.some((x: any) => x.isReportGenerating);
            this.isDeviceCommunicationDownloadedLinkAvailable = this.deviceCommunicationReports.some((x: any) => !x.isReportGenerating);
            this.deviceCommunicationfileUrl = this.deviceCommunicationReports.find((x: any) => !x.isReportGenerating);
            if ((type == 'download' || type == 'getStatus') && !this.isDeviceCommunicationDownloadignInprogress) {
              this.sharedService.showMessageDialog(
                'Report Generation Completed',
                'Please Click Generated Report to get excel file',
                SweetAlertIcon.INFO
              )
              // this.sharedService.downloadUtilityFiles(this.meterfileUrl.name, 'meterreading.xlsx');
            } else if ((type == 'getStatus' || type == 'download') && this.isDeviceCommunicationDownloadignInprogress) {

              this.sharedService.showMessageDialog(
                'Report Generation In Progress',
                'The requested report is currently being generated. Once completed, you can download it by clicking on the <strong>"Generated Report',
                SweetAlertIcon.INFO
              )
            }

          } else {
            this.hidePreviouseDeviceCommunicationReportavailableBtn = true;
          }
          this.loaderService.setLoading(false);
        }
      }
    })

  }





  exportMeterReadingReports() {
    this.utilityValidationMessage = '';  // Resetting utility validation message
    this.meterreadingdateRangeValidationMessage = '';     // Resetting month validation message
    this.flatNumberValidationMessage = ''; // Resetting flat number validation message

    let isValid = true;

    // Validate selected utilities
    if (!this.meterReading_selectedUtility || this.meterReading_selectedUtility.length === 0) {
      this.utilityValidationMessage = 'Please select at least one utility.';
      isValid = false;
    }

    // Validate selected month
    if (!this.meterReadingDateRange || this.meterReadingDateRange.length == 0) {
      this.meterreadingdateRangeValidationMessage = 'Please select a date range.';
      isValid = false;
    }

    // Validate selected flat numbers
    // if (!this.meterReading_selectedFlatNumber || this.meterReading_selectedFlatNumber.length === 0) {
    //   this.flatNumberValidationMessage = 'Please select at least one flat number.';
    //   isValid = false;
    // }


    if (isValid) {
      this.loaderService.setLoading(true);
      const fromDate = new Date(this.meterReadingDateRange[0].getTime() - this.meterReadingDateRange[0].getTimezoneOffset() * 60000);
      const toDate = new Date(this.meterReadingDateRange[1].getTime() - this.meterReadingDateRange[1].getTimezoneOffset() * 60000);

      let payload = {
        "fromDate": fromDate.toISOString().slice(0, 10), // Extracting "YYYY-MM-DD"
        "toDate": toDate.toISOString().slice(0, 10),     // Extracting "YYYY-MM-DD"
        "utilityMasterId": this.meterReading_selectedUtility && this.meterReading_selectedUtility.length > 0
          ? (this.meterReading_selectedUtility[0] === 'all' ? null : this.meterReading_selectedUtility)
          : null, // Handling cases where selectedUtility is empty or "all"
        "projectId": this.projectId,
        "residentialUnitId": this.meterReading_selectedFlatNumber && this.meterReading_selectedFlatNumber.length > 0
          ? (this.meterReading_selectedFlatNumber[0] === 'all' ? null : this.meterReading_selectedFlatNumber)
          : null,
        "userid": this.userId
        // "valveStatus": this.selectedValve_Status,
      };
      // this.reportService.exportReport(payload, 'MeterReadings')
      // Assuming you're calling the exportReport method somewhere in your component
      this.reportService.exportReportMeterReadings(payload, 'MeterReadings').subscribe(
        (response: any) => {
          if (response.statusCode == 200) {
            this.getLatestMeterReadingsReportStatus('download')
            // console.log(response.message); // "Report downloaded successfully."
          } else {
            //  console.log(response.message); // "No Data Found." or any other message
          }
        },
        (error) => {
          console.error('Error while exporting report:', error);
        }
      );

    }
  }


  exportBillGenerationReports() {
    this.utilityValidationMessage = '';  // Resetting utility validation message
    this.billgendateRangeValidationMessage = '';     // Resetting month validation message
    this.flatNumberValidationMessage = ''; // Resetting flat number validation message

    let isValid = true;

    // Validate selected utilities
    if (!this.billgeneration_selectedUtility || this.billgeneration_selectedUtility.length === 0) {
      this.utilityValidationMessage = 'Please select at least one utility.';
      isValid = false;
    }

    // Validate selected month
    if (!this.billGenerationDateRange || this.billGenerationDateRange.length == 0) {
      this.billgendateRangeValidationMessage = 'Please select a date range.';
      isValid = false;
    }

    // Validate selected flat numbers
    if (!this.billGeneration_selectedFlatNumber || this.billGeneration_selectedFlatNumber.length === 0) {
      this.flatNumberValidationMessage = 'Please select at least one flat number.';
      isValid = false;
    }

    if (isValid) {
      this.loaderService.setLoading(true);

      // Adjust dates to handle timezone offset
      const fromDate = new Date(this.billGenerationDateRange[0].getTime() - this.billGenerationDateRange[0].getTimezoneOffset() * 60000);
      const toDate = new Date(this.billGenerationDateRange[1].getTime() - this.billGenerationDateRange[1].getTimezoneOffset() * 60000);

      let payload = {
        "fromDate": fromDate.toISOString().slice(0, 10), // "YYYY-MM-DD" format without timezone shift
        "toDate": toDate.toISOString().slice(0, 10),     // "YYYY-MM-DD" format without timezone shift
        "utilityMasterId": this.billgeneration_selectedUtility && this.billgeneration_selectedUtility.length > 0
          ? (this.billgeneration_selectedUtility[0] === 'all' ? null : this.billgeneration_selectedUtility)
          : null, // Handling cases where selectedUtility is empty or "all"
        "projectId": this.projectId,
        "residentialUnitId": this.billGeneration_selectedFlatNumber && this.billGeneration_selectedFlatNumber.length > 0
          ? (this.billGeneration_selectedFlatNumber[0] === 'all' ? null : this.billGeneration_selectedFlatNumber)
          : null,
        "status": this.selectedValve_Status_MeterReadings,
      };
      this.reportService.exportReport(payload, 'BillGeneration');
    }

  }



  exportWalletBalanceReports() {
    this.utilityValidationMessage = '';  // Resetting utility validation message
    this.consymerWalletdateRangeValidationMessage = '';     // Resetting month validation message
    // this.flatNumberValidationMessage = ''; // Resetting flat number validation message

    let isValid = true;

    // Validate selected month
    if (!this.walletBalanceDateRange || this.walletBalanceDateRange.length == 0) {
      this.consymerWalletdateRangeValidationMessage = 'Please select a date range.';
      isValid = false;
    }

    // Validate selected flat numbers
    if (!this.selected_walletbalance || this.selected_walletbalance.length === 0) {
      this.flatNumberValidationMessage = 'Please select at least one Status Type.';
      isValid = false;
    }

    if (isValid) {
      this.loaderService.setLoading(true);
      const fromDate = new Date(this.walletBalanceDateRange[0].getTime() - this.walletBalanceDateRange[0].getTimezoneOffset() * 60000);
      const toDate = new Date(this.walletBalanceDateRange[1].getTime() - this.walletBalanceDateRange[1].getTimezoneOffset() * 60000);

      let payload = {
        "fromDate": fromDate.toISOString().slice(0, 10), // Extracting "YYYY-MM-DD"
        "toDate": toDate.toISOString().slice(0, 10),     // Extracting "YYYY-MM-DD"
        "projectId": this.projectId,
        "status": this.selected_walletbalance && this.selected_walletbalance.length > 0
          ? (this.selected_walletbalance[0] === 'all' ? null : this.selected_walletbalance)
          : null,
        "residentialUnitId": this.wallet_selectedFlatNumber && this.wallet_selectedFlatNumber.length > 0
          ? (this.wallet_selectedFlatNumber[0] === 'all' ? null : this.wallet_selectedFlatNumber)
          : null,
      };
      this.reportService.exportReport(payload, 'WalletBalance');
    }
  }

  exportWalletHistoryReports() {
    this.utilityValidationMessage = '';  // Resetting utility validation message
    this.waleyHistorydateRangeValidationMessage = '';     // Resetting month validation message
    this.flatNumberValidationMessage = ''; // Resetting flat number validation message

    let isValid = true;

    // Validate selected month
    if (!this.walletHistoryDateRange || this.walletHistoryDateRange.length == 0) {
      this.waleyHistorydateRangeValidationMessage = 'Please select a date range.';
      isValid = false;
    }

    // Validate selected flat numbers
    if (!this.walletHistory_selectedUtility || this.walletHistory_selectedUtility.length === 0) {
      this.utilityValidationMessage = 'Please select at least one utility.';
      isValid = false;
    }

    if (!this.wallet_FlatNumber || this.wallet_FlatNumber.length == 0) {
      this.flatNumberValidationMessage = 'Please select flat number.';
      isValid = false;
    }



    if (isValid) {
      this.loaderService.setLoading(true);
      const fromDate = new Date(this.walletHistoryDateRange[0].getTime() - this.walletHistoryDateRange[0].getTimezoneOffset() * 60000);
      const toDate = new Date(this.walletHistoryDateRange[1].getTime() - this.walletHistoryDateRange[1].getTimezoneOffset() * 60000);

      let payload = {
        "residentialUnitId": this.wallet_FlatNumber && this.wallet_FlatNumber.length > 0
          ? (this.wallet_FlatNumber[0] === 'all' ? null : this.wallet_FlatNumber)
          : null,
        "fromDate": fromDate.toISOString().slice(0, 10), // Extracting "YYYY-MM-DD"
        "toDate": toDate.toISOString().slice(0, 10),     // Extracting "YYYY-MM-DD"
        "projectId": this.projectId,
        "utilityMasterId": this.walletHistory_selectedUtility && this.walletHistory_selectedUtility.length > 0
          ? (this.walletHistory_selectedUtility[0] === 'all' ? null : this.walletHistory_selectedUtility)
          : null, // Handling cases where selectedUtility is empty or "all"
      };
      this.reportService.exportReport(payload, 'WalletHistory');
    }
  }



  onSelectAllUtility() {
    this.isUtilityChecked = !this.isUtilityChecked;
    this.selectedUtility = this.isUtilityChecked
      ? this.configData?.utilities.slice()
      : [];
  }
  isUtilityItemSelected(item: any) {
    const isSelected = this.selectedUtility.some((x: any) => x.id === item.id);
    this.isUtilityChecked =
      this.utilityList.length === this.selectedUtility.length;

    return isSelected;
  }

  utilityValueChange(value: any): void {
    if (value.length > 0) {
      let selectAlldata = value.filter((x: any) => {
        return x.id == 'selectAll';
      });
      if (selectAlldata.length > 0) {
        this.selectedUtility = this.configData?.utilities;
      }
    } else {
      this.selectedUtility = [];
      this.isUtilityChecked = false;
    }
  }
  get isUtilityIndet() {
    return (
      this.selectedUtility.length !== 0 &&
      this.selectedUtility.length !== this.configData.utilities.length
    );
  }

  get isBatteryIndet() {
    return (
      this.selectedBatteryLevel.length !== 0 &&
      this.selectedBatteryLevel.length !== this.batteryLevel.length
    );
  }
  // onSelectBatteryLevelAll() {
  //   this.isBatteryChecked = !this.isBatteryChecked;
  //   this.selectedBatteryLevel = this.isBatteryChecked
  //     ? this.batteryLevel.slice()
  //     : [];
  // }

  // isBatteryItemSelected(item: any) {
  //   const isSelected = this.selectedBatteryLevel.some(
  //     (x: any) => x.id === item.id
  //   );
  //   this.isBatteryChecked =
  //     this.batteryLevel.length === this.selectedBatteryLevel.length;
  //   return isSelected;
  // }


  public onFilterProjectsChange(searchTerm: any): void {
    const contains = (value: string) => (item: { name: string; id: number }) =>
      item.name.toLowerCase().includes(value.toLowerCase());

    this.projectsList = this.configData?.utilities.filter(contains(searchTerm));
  }

  public onFilterUtilitiesChange(searchTerm: any): void {

    // Convert searchTerm to a string, default to an empty string if it's not valid
    const term = typeof searchTerm === 'string' ? searchTerm : '';
    const contains = (value: string) => (item: { name: string; id: string }) =>
      item.name.toLowerCase().includes(value.toLowerCase());

    // Assuming configData.utilities is an array of utility objects

    this.utilityList = this.configData?.utilities.filter(contains(term)) || [];
  }

  public onFilterBatteryLevelUtilitiesChange(searchTerm: any): void {
    // Convert searchTerm to a string, default to an empty string if it's not valid
    const term = typeof searchTerm === 'string' ? searchTerm : '';
    const contains = (value: string) => (item: { name: string; id: string }) =>
      item.name.toLowerCase().includes(value.toLowerCase());

    // Assuming configData.utilities is an array of utility objects

    this.BatteryLevelutilityList = this.BatteryLevelutilityListDup.filter(contains(term)) || [];
  }

  public onFilteWalletChange(searchTerm: any): void {

    console.log(this.selected_walletbalance)
    console.log(searchTerm)
    // Convert searchTerm to a string, default to an empty string if it's not valid
    const term = typeof searchTerm === 'string' ? searchTerm : '';
    const contains = (value: string) => (item: { name: string; id: string }) =>
      item.name.toLowerCase().includes(value.toLowerCase());

    // Assuming configData.utilities is an array of utility objects

    this.WalletBalance_Status = WalletBalanceStatus.filter(contains(term)) || [];
    console.log(this.WalletBalance_Status)
  }

  public onFilterFlatsChange(searchTerm: any): void {

    // Convert searchTerm to a string, default to an empty string if it's not valid
    const term = typeof searchTerm === 'string' ? searchTerm : '';
    const contains = (value: string) => (item: { name: string; id: string }) =>
      item.name.toLowerCase().includes(value.toLowerCase());

    // Assuming configData.utilities is an array of utility objects

    this.flatnumbersList = this.flatnumbersListDup.filter(contains(term)) || [];
  }

  public onFilterWalletFlatsChange(searchTerm: any): void {
    // Convert searchTerm to a string, default to an empty string if it's not valid
    const term = typeof searchTerm === 'string' ? searchTerm : '';
    const contains = (value: string) => (item: { name: string; id: string }) =>
      item.name.toLowerCase().includes(value.toLowerCase());

    // Assuming configData.utilities is an array of utility objects

    this.flatnumbersList = this.walletBanlanceFalttemList.filter(contains(term)) || [];
  }



  // public onFilterMonthsChange(searchTerm: string): void {
  //   const contains = (value: string) => (item: { name: string; id: string }) =>
  //     item.name.toLowerCase().includes(value.toLowerCase());

  //   this.monthsList = Months.filter(contains(searchTerm));
  // }

  public onFilterMonthsChange(searchTerm: string | undefined | null): void {
    // If searchTerm is empty or null, reset the list to the full list
    if (!searchTerm) {
      this.monthsList = [...this.dupMonthsList]; // Restore the full list
      return;
    }

    console.log('Filtered Months:', this.monthsList);
  }



  public onSelectUtility(selectedItems: string[]): void {
    // Ensure selectedItems is an array
    const itemsArray = Array.isArray(selectedItems) ? selectedItems : [selectedItems];

    // If "All Utilities" is selected, deselect all other utilities
    if (itemsArray.some((item: any) => item.id === 'all')) {
      this.selectedUtility = ['all'];
    } else {
      // If "All Utilities" is currently selected and another item is selected, remove "All Utilities"
      if (this.selectedUtility.includes('all')) {
        this.selectedUtility = this.selectedUtility.filter((item: any) => item !== 'all');
      } else {
        // Check if all utilities are selected
        const totalUtilityCount = this.configData?.utilities.length || 0;
        if (this.selectedUtility.length === totalUtilityCount - 1) {
          this.selectedUtility = ['all']; // Select "All Utilities" if all individual utilities are selected
        }
      }
    }
  }

  public onBatteryLevelSelectUtility(selectedItems: string[]): void {
    // Ensure selectedItems is an array
    const itemsArray = Array.isArray(selectedItems) ? selectedItems : [selectedItems];

    // If "All Utilities" is selected, deselect all other utilities
    if (itemsArray.some((item: any) => item.id === 'all')) {
      this.selectedUtility = ['all'];
    } else {
      // If "All Utilities" is currently selected and another item is selected, remove "All Utilities"
      if (this.selectedUtility.includes('all')) {
        this.selectedUtility = this.selectedUtility.filter((item: any) => item !== 'all');
      } else {
        // Check if all utilities are selected
        const totalUtilityCount = this.BatteryLevelutilityListDup.length || 0;
        if (this.selectedUtility.length === totalUtilityCount - 1) {
          this.selectedUtility = ['all']; // Select "All Utilities" if all individual utilities are selected
        }
      }
    }
  }



  public onSelectUtilityInConsumption(selectedItems: string[]): void {
    // Ensure selectedItems is an array
    const itemsArray = Array.isArray(selectedItems) ? selectedItems : [selectedItems];

    // If "All Utilities" is selected, deselect all other utilities
    if (itemsArray.some((item: any) => item.id === 'all')) {
      this.consumption_selectedUtility = ['all'];
    } else {
      // If "All Utilities" is currently selected and another item is selected, remove "All Utilities"
      if (this.consumption_selectedUtility.includes('all')) {
        this.consumption_selectedUtility = this.consumption_selectedUtility.filter((item: any) => item !== 'all');
      } else {
        // Check if all utilities are selected
        const totalUtilityCount = this.configData?.utilities.length || 0;
        if (this.consumption_selectedUtility.length === totalUtilityCount - 1) {
          this.consumption_selectedUtility = ['all']; // Select "All Utilities" if all individual utilities are selected
        }
      }
    }
  }


  public onSelectUtilityInDailyConsumption(selectedItems: string[]): void {
    // Ensure selectedItems is an array
    const itemsArray = Array.isArray(selectedItems) ? selectedItems : [selectedItems];

    // If "All Utilities" is selected, deselect all other utilities
    if (itemsArray.some((item: any) => item.id === 'all')) {
      this.dailyconsumption_selectedUtility = ['all'];
    } else {
      // If "All Utilities" is currently selected and another item is selected, remove "All Utilities"
      if (this.dailyconsumption_selectedUtility.includes('all')) {
        this.dailyconsumption_selectedUtility = this.dailyconsumption_selectedUtility.filter((item: any) => item !== 'all');
      } else {
        // Check if all utilities are selected
        const totalUtilityCount = this.configData?.utilities.length || 0;
        if (this.dailyconsumption_selectedUtility.length === totalUtilityCount - 1) {
          this.dailyconsumption_selectedUtility = ['all']; // Select "All Utilities" if all individual utilities are selected
        }
      }
    }
  }


  public onSelectWalletType(selectedItems: string[]): void {
    // Ensure selectedItems is an array
    const itemsArray = Array.isArray(selectedItems) ? selectedItems : [selectedItems];

    // If "All Utilities" is selected, deselect all other utilities
    if (itemsArray.some((item: any) => item.id === 'all')) {
      this.selected_walletbalance = ['all'];
    } else {
      // If "All Utilities" is currently selected and another item is selected, remove "All Utilities"
      if (this.selected_walletbalance.includes('all')) {
        this.selected_walletbalance = this.selected_walletbalance.filter((item: any) => item !== 'all');
      } else {
        // Check if all utilities are selected
        const totalstatusCount = this.WalletBalance_Status.length || 0;
        if (this.selected_walletbalance.length === totalstatusCount - 1) {
          this.selected_walletbalance = ['all']; // Select "All Utilities" if all individual utilities are selected
        }
      }
    }
  }


  public onSelectUtilityInBillWiseConsumption(selectedItems: string[]): void {
    // Ensure selectedItems is an array
    const itemsArray = Array.isArray(selectedItems) ? selectedItems : [selectedItems];

    // If "All Utilities" is selected, deselect all other utilities
    if (itemsArray.some((item: any) => item.id === 'all')) {
      this.billwise_selectedUtility = ['all'];
    } else {
      // If "All Utilities" is currently selected and another item is selected, remove "All Utilities"
      if (this.billwise_selectedUtility.includes('all')) {
        this.billwise_selectedUtility = this.billwise_selectedUtility.filter((item: any) => item !== 'all');
      } else {
        // Check if all utilities are selected
        const totalUtilityCount = this.configData?.utilities.length || 0;
        if (this.billwise_selectedUtility.length === totalUtilityCount - 1) {
          this.billwise_selectedUtility = ['all']; // Select "All Utilities" if all individual utilities are selected
        }
      }
    }
  }



  public onSelectUtilityInDeviceCommunication(selectedItems: string[]): void {
    // Ensure selectedItems is an array
    const itemsArray = Array.isArray(selectedItems) ? selectedItems : [selectedItems];

    // If "All Utilities" is selected, deselect all other utilities
    if (itemsArray.some((item: any) => item.id === 'all')) {
      this.deviceCommunication_selectedUtility = ['all'];
    } else {
      // If "All Utilities" is currently selected and another item is selected, remove "All Utilities"
      if (this.deviceCommunication_selectedUtility.includes('all')) {
        this.deviceCommunication_selectedUtility = this.deviceCommunication_selectedUtility.filter((item: any) => item !== 'all');
      } else {
        // Check if all utilities are selected
        const totalUtilityCount = this.configData?.utilities.length || 0;
        if (this.deviceCommunication_selectedUtility.length === totalUtilityCount - 1) {
          this.deviceCommunication_selectedUtility = ['all']; // Select "All Utilities" if all individual utilities are selected
        }
      }
    }
  }

  public onSelectUtilityInMeterReading(selectedItems: string[]): void {
    // Ensure selectedItems is an array
    const itemsArray = Array.isArray(selectedItems) ? selectedItems : [selectedItems];

    // If "All Utilities" is selected, deselect all other utilities
    if (itemsArray.some((item: any) => item.id === 'all')) {
      this.meterReading_selectedUtility = ['all'];
    } else {
      // If "All Utilities" is currently selected and another item is selected, remove "All Utilities"
      if (this.meterReading_selectedUtility.includes('all')) {
        this.meterReading_selectedUtility = this.meterReading_selectedUtility.filter((item: any) => item !== 'all');
      } else {
        // Check if all utilities are selected
        const totalUtilityCount = this.configData?.utilities.length || 0;
        if (this.meterReading_selectedUtility.length === totalUtilityCount - 1) {
          this.meterReading_selectedUtility = ['all']; // Select "All Utilities" if all individual utilities are selected
        }
      }
    }
  }


  public onSelectUtilityInBillGenerationMeterReading(selectedItems: string[]): void {
    // Ensure selectedItems is an array
    const itemsArray = Array.isArray(selectedItems) ? selectedItems : [selectedItems];

    // If "All Utilities" is selected, deselect all other utilities
    if (itemsArray.some((item: any) => item.id === 'all')) {
      this.billgeneration_selectedUtility = ['all'];
    } else {
      // If "All Utilities" is currently selected and another item is selected, remove "All Utilities"
      if (this.billgeneration_selectedUtility.includes('all')) {
        this.billgeneration_selectedUtility = this.billgeneration_selectedUtility.filter((item: any) => item !== 'all');
      } else {
        // Check if all utilities are selected
        const totalUtilityCount = this.configData?.utilities.length || 0;
        if (this.billgeneration_selectedUtility.length === totalUtilityCount - 1) {
          this.billgeneration_selectedUtility = ['all']; // Select "All Utilities" if all individual utilities are selected
        }
      }
    }
  }


  public onSelectUtilityInWalletHistoryReport(selectedItems: string[]): void {
    // Ensure selectedItems is an array
    const itemsArray = Array.isArray(selectedItems) ? selectedItems : [selectedItems];

    // If "All Utilities" is selected, deselect all other utilities
    if (itemsArray.some((item: any) => item.id === 'all')) {
      this.walletHistory_selectedUtility = ['all'];
    } else {
      // If "All Utilities" is currently selected and another item is selected, remove "All Utilities"
      if (this.walletHistory_selectedUtility.includes('all')) {
        this.walletHistory_selectedUtility = this.walletHistory_selectedUtility.filter((item: any) => item !== 'all');
      } else {
        // Check if all utilities are selected
        const totalUtilityCount = this.configData?.utilities.length || 0;
        if (this.walletHistory_selectedUtility.length === totalUtilityCount - 1) {
          this.walletHistory_selectedUtility = ['all']; // Select "All Utilities" if all individual utilities are selected
        }
      }
    }
  }


  public onSelectUtilityInWalletHistory(selectedItems: string[]): void {
    // Ensure selectedItems is an array
    const itemsArray = Array.isArray(selectedItems) ? selectedItems : [selectedItems];

    // If "All Utilities" is selected, deselect all other utilities
    if (itemsArray.some((item: any) => item.id === 'all')) {
      this.walletHistory_selectedUtility = ['all'];
    } else {
      // If "All Utilities" is currently selected and another item is selected, remove "All Utilities"
      if (this.walletHistory_selectedUtility.includes('all')) {
        this.walletHistory_selectedUtility = this.walletHistory_selectedUtility.filter((item: any) => item !== 'all');
      } else {
        // Check if all utilities are selected
        const totalUtilityCount = this.configData?.utilities.length || 0;
        if (this.walletHistory_selectedUtility.length === totalUtilityCount - 1) {
          this.walletHistory_selectedUtility = ['all']; // Select "All Utilities" if all individual utilities are selected
        }
      }
    }
  }



  public onSelectUtilityInBillGeneration(selectedItems: string[]): void {
    // Ensure selectedItems is an array
    const itemsArray = Array.isArray(selectedItems) ? selectedItems : [selectedItems];

    // If "All Utilities" is selected, deselect all other utilities
    if (itemsArray.some((item: any) => item.id === 'all')) {
      this.billgeneration_selectedUtility = ['all'];
    } else {
      // If "All Utilities" is currently selected and another item is selected, remove "All Utilities"
      if (this.billgeneration_selectedUtility.includes('all')) {
        this.billgeneration_selectedUtility = this.billgeneration_selectedUtility.filter((item: any) => item !== 'all');
      } else {
        // Check if all utilities are selected
        const totalUtilityCount = this.configData?.utilities.length || 0;
        if (this.billgeneration_selectedUtility.length === totalUtilityCount - 1) {
          this.billgeneration_selectedUtility = ['all']; // Select "All Utilities" if all individual utilities are selected
        }
      }
    }
  }


  public onSelectFlatsInBillWiseConsumption(selectedItems: string[]): void {
    // Ensure selectedItems is an array
    const itemsArray = Array.isArray(selectedItems) ? selectedItems : [selectedItems];

    // If "All Utilities" is selected, deselect all other utilities
    if (itemsArray.some((item: any) => item.id === 'all')) {
      this.billwise_selectedFlatNumber = ['all'];
    } else {
      // If "All Utilities" is currently selected and another item is selected, remove "All Utilities"
      if (this.billwise_selectedFlatNumber.includes('all')) {
        this.billwise_selectedFlatNumber = this.billwise_selectedFlatNumber.filter((item: any) => item !== 'all');
      } else {
        // Check if all utilities are selected
        const totalUtilityCount = this.flatnumbersListDup.length || 0;
        if (this.billwise_selectedFlatNumber.length === totalUtilityCount - 1) {
          this.billwise_selectedFlatNumber = ['all']; // Select "All Utilities" if all individual utilities are selected
        }
      }
    }
  }



  public onSelectMonthsForWalletBalanceReports(selectedItems: string[]): void {
    // Ensure selectedItems is an array
    const itemsArray = Array.isArray(selectedItems) ? selectedItems : [selectedItems];

    // If "All Utilities" is selected, deselect all other utilities
    if (itemsArray.some((item: any) => item.id === 'all')) {
      this.selectedMonthsForWalletBalanceReport = ['all'];
    } else {
      // If "All Utilities" is currently selected and another item is selected, remove "All Utilities"
      if (this.selectedMonthsForWalletBalanceReport.includes('all')) {
        this.selectedMonthsForWalletBalanceReport = this.selectedMonthsForWalletBalanceReport.filter((item: any) => item !== 'all');
      } else {
        // Check if all utilities are selected
        const totalUtilityCount = this.dupMonthsListWithAll.length || 0;
        if (this.selectedMonthsForWalletBalanceReport.length === totalUtilityCount - 1) {
          this.selectedMonthsForWalletBalanceReport = ['all']; // Select "All Utilities" if all individual utilities are selected
        }
      }
    }
  }


  public onSelectFlatsInDeviceCommunication(selectedItems: string[]): void {
    // Ensure selectedItems is an array
    const itemsArray = Array.isArray(selectedItems) ? selectedItems : [selectedItems];

    // If "All Utilities" is selected, deselect all other utilities
    if (itemsArray.some((item: any) => item.id === 'all')) {
      this.deviceCommunication_selectedFlatNumber = ['all'];
    } else {
      // If "All Utilities" is currently selected and another item is selected, remove "All Utilities"
      if (this.deviceCommunication_selectedFlatNumber.includes('all')) {
        this.deviceCommunication_selectedFlatNumber = this.deviceCommunication_selectedFlatNumber.filter((item: any) => item !== 'all');
      } else {
        // Check if all utilities are selected
        const totalUtilityCount = this.flatnumbersListDup.length || 0;
        if (this.deviceCommunication_selectedFlatNumber.length === totalUtilityCount - 1) {
          this.deviceCommunication_selectedFlatNumber = ['all']; // Select "All Utilities" if all individual utilities are selected
        }
      }
    }
  }

  public onSelectFlatsInMeterReadings(selectedItems: string[]): void {
    // Ensure selectedItems is an array
    const itemsArray = Array.isArray(selectedItems) ? selectedItems : [selectedItems];

    // If "All Utilities" is selected, deselect all other utilities
    if (itemsArray.some((item: any) => item.id === 'all')) {
      this.meterReading_selectedFlatNumber = ['all'];
    } else {
      // If "All Utilities" is currently selected and another item is selected, remove "All Utilities"
      if (this.meterReading_selectedFlatNumber.includes('all')) {
        this.meterReading_selectedFlatNumber = this.meterReading_selectedFlatNumber.filter((item: any) => item !== 'all');
      } else {
        // Check if all utilities are selected
        const totalUtilityCount = this.flatnumbersListDup.length || 0;
        if (this.meterReading_selectedFlatNumber.length === totalUtilityCount - 1) {
          this.meterReading_selectedFlatNumber = ['all']; // Select "All Utilities" if all individual utilities are selected
        }
      }
    }
  }

  public onSelectFlatsInBillGeneration(selectedItems: string[]): void {
    // Ensure selectedItems is an array
    const itemsArray = Array.isArray(selectedItems) ? selectedItems : [selectedItems];

    // If "All Utilities" is selected, deselect all other utilities
    if (itemsArray.some((item: any) => item.id === 'all')) {
      this.billGeneration_selectedFlatNumber = ['all'];
    } else {
      // If "All Utilities" is currently selected and another item is selected, remove "All Utilities"
      if (this.billGeneration_selectedFlatNumber.includes('all')) {
        this.billGeneration_selectedFlatNumber = this.billGeneration_selectedFlatNumber.filter((item: any) => item !== 'all');
      } else {
        // Check if all utilities are selected
        const totalUtilityCount = this.flatnumbersListDup.length || 0;
        if (this.billGeneration_selectedFlatNumber.length === totalUtilityCount - 1) {
          this.billGeneration_selectedFlatNumber = ['all']; // Select "All Utilities" if all individual utilities are selected
        }
      }
    }
  }

  public onSelectFlatsIndailyConsumption(selectedItems: string[]): void {
    // Ensure selectedItems is an array
    const itemsArray = Array.isArray(selectedItems) ? selectedItems : [selectedItems];

    // If "All Utilities" is selected, deselect all other utilities
    if (itemsArray.some((item: any) => item.id === 'all')) {
      this.dailyConsumption_selectedFlatNumber = ['all'];
    } else {
      // If "All Utilities" is currently selected and another item is selected, remove "All Utilities"
      if (this.dailyConsumption_selectedFlatNumber.includes('all')) {
        this.dailyConsumption_selectedFlatNumber = this.dailyConsumption_selectedFlatNumber.filter((item: any) => item !== 'all');
      } else {
        // Check if all utilities are selected
        const totalUtilityCount = this.flatnumbersListDup.length || 0;
        if (this.dailyConsumption_selectedFlatNumber.length === totalUtilityCount - 1) {
          this.dailyConsumption_selectedFlatNumber = ['all']; // Select "All Utilities" if all individual utilities are selected
        }
      }
    }
  }
  public onSelectFlatsInConsumption(selectedItems: string[]): void {
    // Ensure selectedItems is an array
    const itemsArray = Array.isArray(selectedItems) ? selectedItems : [selectedItems];

    // If "All Utilities" is selected, deselect all other utilities
    if (itemsArray.some((item: any) => item.id === 'all')) {
      this.Consumption_selectedFlatNumber = ['all'];
    } else {
      // If "All Utilities" is currently selected and another item is selected, remove "All Utilities"
      if (this.Consumption_selectedFlatNumber.includes('all')) {
        this.Consumption_selectedFlatNumber = this.Consumption_selectedFlatNumber.filter((item: any) => item !== 'all');
      } else {
        // Check if all utilities are selected
        const totalUtilityCount = this.flatnumbersListDup.length || 0;
        if (this.Consumption_selectedFlatNumber.length === totalUtilityCount - 1) {
          this.Consumption_selectedFlatNumber = ['all']; // Select "All Utilities" if all individual utilities are selected
        }
      }
    }
  }

  public onSelectFlatsInWalletBalance(selectedItems: string[]): void {
    // Ensure selectedItems is an array
    const itemsArray = Array.isArray(selectedItems) ? selectedItems : [selectedItems];

    // If "All Utilities" is selected, deselect all other utilities
    if (itemsArray.some((item: any) => item.id === 'all')) {
      this.wallet_selectedFlatNumber = ['all'];
    } else {
      // If "All Utilities" is currently selected and another item is selected, remove "All Utilities"
      if (this.wallet_selectedFlatNumber.includes('all')) {
        this.wallet_selectedFlatNumber = this.wallet_selectedFlatNumber.filter((item: any) => item !== 'all');
      } else {
        // Check if all utilities are selected
        const totalUtilityCount = this.walletBanlanceFalttemList.length || 0;
        if (this.wallet_selectedFlatNumber.length === totalUtilityCount - 1) {
          this.wallet_selectedFlatNumber = ['all']; // Select "All Utilities" if all individual utilities are selected
        }
      }
    }
  }


  public onSelectFlatsInWalletHistory(selectedItems: string[]): void {
    // Ensure selectedItems is an array
    const itemsArray = Array.isArray(selectedItems) ? selectedItems : [selectedItems];
      if(selectedItems){
        this.flatNumberValidationMessage = ''
      }
    
    // If "All Utilities" is selected, deselect all other utilities
    if (itemsArray.some((item: any) => item.id === 'all')) {
      this.wallet_FlatNumber = ['all'];
    } else {
      // If "All Utilities" is currently selected and another item is selected, remove "All Utilities"
      if (this.wallet_FlatNumber.includes('all')) {
        this.wallet_FlatNumber = this.wallet_FlatNumber.filter((item: any) => item !== 'all');
      } else {
        // Check if all utilities are selected
        const totalUtilityCount = this.walletBanlanceFalttemList.length || 0;
        if (this.wallet_FlatNumber.length === totalUtilityCount - 1) {
          this.wallet_FlatNumber = ['all']; // Select "All Utilities" if all individual utilities are selected
        }
      }
    }
  }


  onSelectSettlmentProject(selectedItems: string[]): void {
    // Ensure selectedItems is an array
    const itemsArray = Array.isArray(selectedItems) ? selectedItems : [selectedItems];

    // If "All Utilities" is selected, deselect all other utilities
    if (itemsArray.some((item: any) => item.id === 'all')) {
      this.selectedSettlementProject = ['all'];
    } else {
      // If "All Utilities" is currently selected and another item is selected, remove "All Utilities"
      if (this.selectedSettlementProject.includes('all')) {
        this.selectedSettlementProject = this.selectedSettlementProject.filter((item: any) => item !== 'all');
      } else {
        // Check if all utilities are selected
        const totalUtilityCount = this.configData.projects.length || 0;
        if (this.selectedSettlementProject.length === totalUtilityCount - 1) {
          this.selectedSettlementProject = ['all']; // Select "All Utilities" if all individual utilities are selected
        }
      }
    }
  }






  onSelectProject(selectedItems: string[]): void {
    // Ensure selectedItems is an array
    const itemsArray = Array.isArray(selectedItems) ? selectedItems : [selectedItems];

    // If "All Utilities" is selected, deselect all other utilities
    if (itemsArray.some((item: any) => item.id === 'all')) {
      this.selectedProject = ['all'];
    } else {
      // If "All Utilities" is currently selected and another item is selected, remove "All Utilities"
      if (this.selectedProject.includes('all')) {
        this.selectedProject = this.selectedProject.filter((item: any) => item !== 'all');
      } else {
        // Check if all utilities are selected
        const totalUtilityCount = this.configData.projects.length || 0;
        if (this.selectedProject.length === totalUtilityCount - 1) {
          this.selectedProject = ['all']; // Select "All Utilities" if all individual utilities are selected
        }
      }
    }
  }

  openCalendarsettlement() {
    // Trigger the click event on the input field to open the datepicker
    const dateInput = document.querySelector('.form-control.settlement');
    dateInput?.dispatchEvent(new Event('click'));
  }

  openCalendar() {
    // Trigger the click event on the input field to open the datepicker
    const dateInput = document.querySelector('.form-control.date');
    dateInput?.dispatchEvent(new Event('click'));
  }

  openCalendarConsumption() {
    // Trigger the click event on the input field to open the datepicker
    const dateInput = document.querySelector('.form-control.dateconsumetion');
    dateInput?.dispatchEvent(new Event('click'));
  }

  openCalendardailyConsumption() {
    // Trigger the click event on the input field to open the datepicker
    const dateInput = document.querySelector('.form-control.dailyconsumetion');
    dateInput?.dispatchEvent(new Event('click'));
  }
  openCalendarDeviceCommunication() {
    // Trigger the click event on the input field to open the datepicker
    const dateInput = document.querySelector('.form-control.datedeviceCommunication');
    dateInput?.dispatchEvent(new Event('click'));
  }
  openCalendarMeterReading() {
    // Trigger the click event on the input field to open the datepicker
    const dateInput = document.querySelector('.form-control.datemeterReading');
    dateInput?.dispatchEvent(new Event('click'));
  }

  openCalendarBillGeneration() {
    // Trigger the click event on the input field to open the datepicker
    const dateInput = document.querySelector('.form-control.datebillGeneration');
    dateInput?.dispatchEvent(new Event('click'));
  }
  openCalendarWalletBalance() {
    // Trigger the click event on the input field to open the datepicker
    const dateInput = document.querySelector('.form-control.datewalletBalanceReport');
    dateInput?.dispatchEvent(new Event('click'));
  }

  openCalendarWalletHistory() {
    // Trigger the click event on the input field to open the datepicker
    const dateInput = document.querySelector('.form-control.datewalletHistory');
    dateInput?.dispatchEvent(new Event('click'));
  }





  formatDateRange(dateRange: (Date | null)[]): string {

    if (dateRange && dateRange.length === 2 && dateRange[0] && dateRange[1]) {
      const startDate = formatDate(dateRange[0], 'dd/MM/yyyy', 'en-US');
      const endDate = formatDate(dateRange[1], 'dd/MM/yyyy', 'en-US');
      return `${startDate} to ${endDate}`;
    }
    return '';  // Return empty string if the date range is not valid
  }





  clearDateSettlement() {
    this.settlemetDateRange = [];// Clear the selected date
  }
  clearDate() {
    this.activationDateRange = [];// Clear the selected date
  }
  clearConsumptionDate() {
    this.consumptionDateRange = [];
  }
  cleardailyConsumptionDate() {
    this.dailyconsumptionDateRange = [];
  }
  clearDeviceCommunicationDate() {
    this.deviceCommunicationDateRange = [];
  }
  clearbillGenerationDate() {
    this.billGenerationDateRange = [];
  }
  clearwalletBalanceDate() {
    this.walletBalanceDateRange = [];
  }


  clearwalletHistoryDate() {
    this.walletHistoryDateRange = [];
  }
  clearMeterReadingDate() {
    this.meterReadingDateRange = [];
  }
  clearBillWiseDate() {
    this.billwiseDateRange = [];
  }
}
